import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from "react-native";
import CheckBox from "../../../components/src/CustomCheckBox";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FlatList } from "react-native-gesture-handler";
import moment from "moment";
import { Box, Typography, Button, styled, IconButton } from "@mui/material";
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

export const capImage = require("../assets/cap.svg");
export const logo = require("../assets/logo.svg");


const StyledBox1 = styled(Box)({
  flexWrap: "wrap",
  gap: "20px",
  "@media (max-width: 500px)": {
    flexDirection: "column",
    alignItems: "flex-start !important",
    gap: "20px"
  },
});

const StyledBox2 = styled(Box)({
  width: "100%",
  flexWrap: "wrap",
  "@media (max-width: 500px)": {
    flexDirection: "column",
    alignItems: "flex-start !important",
    gap: "10px"
  },
  "@media (max-width: 420px)": {
    gap: "30px"
  },
});

const StyledBox3 = styled(Box)({
  flexWrap: "wrap",
  "@media (max-width: 510px)": {
    alignItems: "center",
  },
});

const StyledBox4 = styled(Box)({
  "@media (max-width: 450px)": {
    display: "none !important"
  },
});

const StyledMenuButton = styled(IconButton)({
  "@media (max-width: 450px)": {
    display: "flex !important"
  },
});

const StyledTypo1 = styled(Typography)({
  "@media (max-width: 510px)": {
    width: "fit-content !important"
  },
});

const StyledTypo2 = styled(Typography)({
  "@media (max-width: 450px)": {
    display: "none"
  },
});

const StyledTypo3 = styled(Typography)({
  "@media (max-width: 430px)": {
    fontSize: "22px !important"
  },
  "@media (max-width: 355px)": {
    fontSize: "20px !important"
  },
});


// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box key={this.state.dataId} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box style={{
          height: "74px", display: "flex", alignItems: "center",
          borderBottom: "1px solid #C1C7CD", gap: "16px", padding: "0px 60px 0px 40px"
        }}>
          <Box style={{ display: "flex", alignItems: "center", flexGrow: 1, gap: "40px" }}>
            <img
              src={logo.default}
              alt="senior_year"
              style={{ width: "195px", height: "22px" }}
            />
            <StyledTypo2 style={{
              width: "fit-content", height: "26px", color: "#020202", fontFamily: "Manrope",
              fontSize: "16px", fontWeight: 500
            }}>
              Find Jobs
            </StyledTypo2>
          </Box>
          <StyledBox4 style={{ display: "flex", alignItems: "center", gap: "16px", }}>
            <Divider orientation="vertical" flexItem />
            <Typography style={{
              width: "fit-content", height: "26px", color: "#020202", fontFamily: "Manrope",
              fontSize: "16px", fontWeight: 500, cursor: "pointer"
            }}>
              David Jones
            </Typography>
            <KeyboardArrowDownIcon
              data-test-id="keyboard-arrow-down-icon"
              style={{ color: "#000000", cursor: "pointer" }}
            />
          </StyledBox4>
          <StyledMenuButton style={{ display: "none" }}>
            <MenuIcon style={{ color: "000" }} />
          </StyledMenuButton>
        </Box>
        <Box style={{
          display: "flex", padding: "40px",
          flexDirection: "column", gap: "40px"
        }}>
          <StyledTypo3 style={{
            width: "fit-content", height: "29px", color: "#25324B",
            fontSize: "24px", fontWeight: 700, fontFamily: "Manrope"
          }}>
            {this.state.dataId === "1" ? "Privacy Policy" : "Terms and Conditions"}
          </StyledTypo3>
          <Typography style={{
            width: "fit-content", height: "fit-content", minHeight: "150px", color: "#333333",
            fontSize: "16px", fontWeight: 400, fontFamily: "Roboto"
          }}>
            {this.state.termsData}
          </Typography>
        </Box>
        <Box style={{
          display: "flex", padding: "40px 50px", background: "#5E70DD",
          flexDirection: "column", gap: "40px"
        }}>
          <StyledBox1 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <StyledBox3 style={{ display: 'flex', flexDirection: "column", gap: "12px" }}>
              <img
                src={capImage.default}
                alt="cap"
                style={{ width: "54px", height: "43px" }}
              />
              <StyledTypo1 style={{
                width: "457px", height: "fit-content", color: "#FFFFFF",
                fontWeight: 400, fontFamily: "Manrope", fontSize: "18px"
              }}>
                Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.
              </StyledTypo1>
            </StyledBox3>
            <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <EmailOutlinedIcon style={{ color: "#FFFFFF" }} />
              <Box style={{
                display: "flex", flexDirection: "column", gap: "2px"
              }}>
                <Typography style={{
                  width: "fit-content", height: "16px", color: "#FFFFFF",
                  fontWeight: 400, fontFamily: "Manrope", fontSize: "12px"
                }}>
                  Contact us at
                </Typography>
                <Typography style={{
                  width: "fit-content", height: "22px", color: "#FFFFFF",
                  fontWeight: 400, fontFamily: "Manrope", fontSize: "16px"
                }}>
                  senioryear@outlook.com
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <CallOutlinedIcon style={{ color: "#FFFFFF" }} />
              <Box style={{
                display: "flex", flexDirection: "column", gap: "2px"
              }}>
                <Typography style={{
                  width: "fit-content", height: "16px", color: "#FFFFFF",
                  fontWeight: 400, fontFamily: "Manrope", fontSize: "12px"
                }}>
                  Have a question?
                </Typography>
                <Typography style={{
                  width: "fit-content", height: "22px", color: "#FFFFFF",
                  fontWeight: 400, fontFamily: "Manrope", fontSize: "16px"
                }}>
                  310-437-2766
                </Typography>
              </Box>
            </Box>
            <Button
              style={{
                width: "108px", height: "34px", background: "#000000",
                padding: "12px 24px", color: "#FFFFFF", textTransform: "none",
                fontStyle: "Epilogue", fontSize: "16ox", fontWeight: 700
              }}
              data-test-id="signUpNavigateTestBtn"
              onClick={() => this.navigateToSignUp()}
            > Sign Up
            </Button>
          </StyledBox1>
          <StyledBox2 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography style={{
              width: "fit-content", height: "20px", color: "#FFFFFF",
              fontWeight: 400, fontFamily: "Roboto", fontSize: "14px"
            }}>
              CompanyName @ 202X. All rights reserved.
            </Typography>
            <Typography style={{
              width: "fit-content", height: "16px", color: "#FFF", cursor:"pointer",
              fontWeight: 400, fontFamily: "Manrope", fontSize: "16px"
            }} 
            onClick={() => this.navigateToTerms()}
            data-test-id="termsConditionHyperLink"
            >
              Term and Conditions
            </Typography>
          </StyledBox2>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  spinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    flex: 1,
    padding: 20,
    fontWeight: "600",
    fontSize: 22,
  },
  termsCondsTitle: {
    fontWeight: "600",
    marginBottom: 20,
    fontSize: 22,
  },
  scrollView: {
    height: "100%",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: 40,
    marginRight: 20,
  },
  termsCondsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    padding: 20,
  },
  button: {
    backgroundColor: "blue",
    marginLeft: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end",
  },
  buttonLabel: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: 20,
  },
});
// Customizable Area End
