Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchMethod = "PATCH"
exports.deleteMethod = "DELETE"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.resetPasswordEndPoint = "/bx_block_profile/passwords"
exports.btnExampleTitle = "CLICK ME";
exports.myProfileText = "My Profile"
exports.deleteAccountEndPoint = "account_block/accounts/delete_account"
// Customizable Area End