import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedValue: string;
  inputValue: string;
  selectedTab: number;
  selectedAge: number;
  ageValue: string;
  progress: number;
  preferenceData: {
    id: string,
    type: string,
    attributes: {
      title: string,
      records: []
    }
  }[];
  qustion1Data: { "index": number, "value": string }
  qustion2Data: { "index": number, "value": string }
  qustion3Data: { "index": number, "value": string }
  qustion4Data: { "index": number, "value": string }
  qustion5Data: { "index": number, "value": string }
  qustion6Data: { "index": number, "value": string }
  qustion7Data: { "index": number, "value": string }
  qustion8Data: { "index": number, "value": string }
  qustion9Data: { "index": number, "value": string }
  selectedData: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PreferenceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  labelTitle: string = "";
  preferenceCallId: string = ""
  getPreferenceCallId: string = ""
  getSelectedAnswersCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
    ];

    this.state = {
    selectedValue:"Choose your Location",
    inputValue:"",
    selectedTab: 1,
    selectedAge: 1 ,
    ageValue: "",
    progress: 0,
    preferenceData: [{
      id: "",
      type: "",
      attributes: {
        title: "",
        records: []
      }
    }],
    qustion1Data: { "index": 100, "value": "" },
    qustion2Data: { "index": 100, "value": "" },
    qustion3Data: { "index": 100, "value": "" },
    qustion4Data: { "index": 100, "value": "" },
    qustion5Data: { "index": 100, "value": "" },
    qustion6Data: { "index": 100, "value": "" },
    qustion7Data: { "index": 100, "value": "" },
    qustion8Data: { "index": 100, "value": "" },
    qustion9Data: { "index": 100, "value": "" },
    selectedData: []
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getPreferenceApi()
    this.getSelectedAnswers()
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ); 
      this.apiEmailLoginCallId =apiRequestCallId

    }

      if (apiRequestCallId === this.getPreferenceCallId) {
        if (responseJson) {
          this.setState({preferenceData: responseJson.data})
  
        }}
        if (apiRequestCallId === this.getSelectedAnswersCallId) {
          if (responseJson) {
            let data = responseJson.answers
            this.handleSelectedData(data)
            this.setState({ selectedData: data })
    
          }}
    
    // Customizable Area End
  }



   // Customizable Area Start

  handleAge = (value: string, index: number, queNumber: number) => {
    
    switch(queNumber)
    {
      case 1: 
     this.setState({qustion1Data: { "index": index , "value": value }}) 
     break;
      case 2: 
     this.setState({qustion2Data: { "index": index , "value": value }}) 
     break;
     case 3: 
     this.setState({qustion3Data: { "index": index , "value": value }})
     break; 
     case 4: 
     this.setState({qustion4Data: { "index": index , "value": value }}) 
     break;
     case 5: 
     this.setState({qustion5Data: { "index": index , "value": value }}) 
     break;
     case 6: 
     this.setState({qustion6Data: { "index": index , "value": value }}) 
     break;
     case 7: 
     this.setState({qustion7Data: { "index": index , "value": value }}) 
     break;
     case 8: 
     this.setState({qustion8Data: { "index": index , "value": value }}) 
     break;
     case 9: 
     this.setState({qustion9Data: { "index": index , "value": value }}) 
     break;

    }

  }

  handleSelectedData = (data: any) => {
    this.setState({
    qustion1Data: { "index": 0, "value": data[0]?.records[0] },
    qustion2Data: { "index": 1, "value": data[1]?.records[0] },
    qustion3Data: { "index": 2, "value": data[2]?.records[0] },
    qustion4Data: { "index": 3, "value": data[3]?.records[0] },
    qustion5Data: { "index": 4, "value": data[4]?.records[0] },
    qustion6Data: { "index": 5, "value": data[5]?.records[0] },
    qustion7Data: { "index": 6, "value": data[6]?.records[0] },
    qustion8Data: { "index": 7, "value": data[7]?.records[0] },
    qustion9Data: { "index": 8, "value": data[8]?.records[0] },})
  }


  handleNavigation = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

  prefenceApi = () => {
    let { qustion1Data,qustion2Data,qustion3Data,qustion4Data,qustion5Data,qustion6Data, qustion7Data,qustion8Data,qustion9Data } = this.state
    let data = JSON.parse(String(localStorage.getItem("login")))
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": data?.token
      };
      let body = {
        "answers": [ 
          `{id: 1,  records:  [${qustion1Data.value}] }`,
          `{id: 2, records:  [${qustion2Data.value}] }`,
          `{id: 3,  records:  [${qustion3Data.value}] }`,
          `{id: 4, records:  [${qustion4Data.value}] }`,
          `{id: 5,  records:  [${qustion5Data.value}] }`,
          `{id: 6, records:  [${qustion6Data.value}] }`,
          `{id: 7,  records:  [${qustion7Data.value}] }`,
          `{id: 8, records:  [${qustion8Data.value}] }`,
          `{id: 9, records:   [${qustion9Data.value}] }`,
         ]
    }

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.preferenceCallId = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.preferenceEndPoint
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchAPiMethod
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getSelectedAnswers = () => {
    let data = JSON.parse(String(localStorage.getItem("login")))
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": data?.token
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSelectedAnswersCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSelectedApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getPreferenceApi = () => {
    let data = JSON.parse(String(localStorage.getItem("login")))
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": data?.token
    };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPreferenceCallId = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPreferenceEndPoint
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

    // Customizable Area End
}
