import React from "react";

// Customizable Area Start
import { Box, Select, Button, Typography, MenuItem, } from "@mui/material";
import { styled } from "@mui/styles";
import { Footer, headerLogo } from "./assets"


const StyledMainDiv = styled(Box)({
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
   "@media (max-width: 457px)": {
       justifyContent:"center",
     backgroundColor:"#F1F5F9",
     },
})



// Customizable Area End

import PreferencesPageController, {
  Props,
} from "./PreferencesPageController";
export default class PreferencesPage extends PreferencesPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderAgeScreen = () => {
    let { qustion1Data } = this.state
    return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px", marginBottom:"20px"}}>
    <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>What is your age?</Typography>
    <Box style={{display:"flex", gap:"12px",flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[0].attributes.records.map((item, index)=>
     <Box style={{height:"41px",padding:" 0px 5px 0px 5px",borderRadius:"30px", backgroundColor:`${(item === qustion1Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
      display:"flex", gap:"12px", justifyContent:"center", alignItems:"center", minWidth:"81px",cursor:"pointer"}}
      onClick={() => this.handleAge(item, index, 1 )}
      data-test-id="ageId"
      >
        <Typography 
        style={{fontFamily: "Manrope",fontSize: "16px",fontWeight: 600,lineHeight: "25.6px", padding: "9px, 32px, 9px, 32px"
        ,color:`${(item === qustion1Data?.value) ? "#FFFFFF" : "#080808"}`,
        }}
        >{item}</Typography></Box> 
    )}
    </Box>
</Box>
}


  renderIntrestScreen = () => {
    let { qustion2Data } = this.state
    return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px",marginBottom:"20px"}}>
    <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>Where are you located?</Typography>
    <Box style={{  gap:"12px", display:"flex", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[1]?.attributes.records.map(( item, index ) =>
     <Box style={{height:"41px",borderRadius:"30px",padding:" 0px 5px 0px 5px", backgroundColor:`${(item === qustion2Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
      display:"flex", gap:"12px",  alignItems:"center",justifyContent:"center", minWidth:"81px",cursor:"pointer"}}
      data-test-id = "interestId"
      onClick={() => this.handleAge( item, index , 2) }
      >
        <Typography 
        style={{fontFamily: "Manrope",padding: "9px, 32px, 9px, 32px",fontSize: "16px",
        fontWeight: 600,lineHeight: "25.6px",color:`${(item === qustion2Data?.value) ? "#FFFFFF" : "#080808"}`, }} >{item}</Typography></Box> 
    )}
    </Box>
</Box>
  }

  renderHelpScreen = () => {
    let { qustion3Data } = this.state
    return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px",marginBottom:"20px"}}>
    <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>What type of work do you prefer?</Typography>
    <Box style={{display:"flex", gap:"12px", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[2]?.attributes.records.map((item, index)=>
     <Box style={{borderRadius:"30px",height:"41px",padding:" 0px 5px 0px 5px", backgroundColor:`${(item === qustion3Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
      display:"flex",  justifyContent:"center", alignItems:"center",gap:"12px", minWidth:"81px",cursor:"pointer"}}
      data-test-id = "helpId"
      onClick={
        () => this.handleAge(item, index, 3)
      }
      >
        <Typography 
        style={{fontSize: "16px",fontWeight: 600,lineHeight: "25.6px", 
        fontFamily: "Manrope", padding: "9px, 32px, 9px, 32px"
        ,color:`${(item === qustion3Data?.value) ? "#FFFFFF" : "#080808"}`,}} >{item}</Typography></Box> 
    )}
    </Box>
</Box>
  }
  renderWorkScreen = () => {
    let { qustion4Data } = this.state
        return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px", marginBottom:"20px"}}>
        <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>What type of schedule do you prefer?</Typography>
        <Box style={ {display: "flex", gap: "12px", flexWrap: "wrap", width: "50%", justifyContent: "center"} }> { this.state.preferenceData[3]?.attributes.records.map((item, index)=>
         <Box style={{padding:" 0px 5px 0px 5px", height:"41px",borderRadius:"30px", backgroundColor:`${(item === qustion4Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
          display:"flex", gap:"12px",alignItems:"center", justifyContent:"center",  minWidth:"81px",cursor:"pointer"}}
          onClick={
            () => this.handleAge(item, index, 4)}
          data-test-id = "workId"
          >
            <Typography 
            style={{lineHeight: "25.6px",fontFamily: "Manrope",fontSize: "16px",fontWeight: 600, padding: "9px, 32px, 9px, 32px"
            ,color:`${(item === qustion4Data?.value) ? "#FFFFFF" : "#080808"}`
            }}
            > {item} </Typography></Box> 
        )}
        </Box>
    </Box>
  }

  renderCertificationScreen = () => {
    let { qustion8Data } = this.state
        return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px", marginBottom:"20px"}}>
        <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>Do you have any certifications?</Typography>
        <Box style={{display:"flex", gap:"12px", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[7]?.attributes.records.map((item, index) =>   <Box style={{height:"41px",
        padding:" 0px 5px 0px 5px",borderRadius:"30px", backgroundColor:`${(item === qustion8Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
          display:"flex", justifyContent:"center", alignItems:"center", minWidth:"81px", gap:"12px",cursor:"pointer"}}
          data-test-id= "certificateId"
          onClick={() => 
            this.handleAge( item, index, 8) }
          >
            <Typography style={{fontFamily: "Manrope",fontSize: "16px",
            fontWeight: 600,lineHeight: "25.6px", padding: "9px, 32px, 9px, 32px"
            ,color:`${( item === qustion8Data?.value) ? "#FFFFFF" : "#080808"}`,}}  >{item}</Typography></Box> 
        )}
        </Box>
    </Box>
  }



  renderSocialMediaDetails = () => {
    let { qustion5Data } = this.state
        return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px",marginBottom:"20px"}}>
        <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>Does this sound like you?</Typography>
        <Box style={{display:"flex", gap:"12px", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[4]?.attributes.records.map((item, index) => <Box 
         style={{height:"41px",padding:" 0px 5px 0px 5px",borderRadius:"30px", backgroundColor:`${(item === qustion5Data?.value) ? "#5E70DD" : "#F4F4F4"}`, display:"flex", 
         gap:"12px", justifyContent:"center", alignItems:"center", minWidth:"81px",cursor:"pointer"}}
          onClick={
            () => this.handleAge( item, index, 5)
        }
          data-test-id = "socialId"
          >
            <Typography  style={{fontFamily: "Manrope",fontSize: "16px",fontWeight: 600,lineHeight: "25.6px", padding: "9px, 32px, 9px, 32px"
              ,color:`${( item === qustion5Data?.value) ? "#FFFFFF" : "#080808"}`
            }} > {item} </Typography></Box> 
        )}
        </Box>
    </Box>
  }



renderJobSiteDetails = () => {
  let { qustion6Data } = this.state
        return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px", marginBottom:"20px"}}>
        <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>What are your interests?</Typography>
        <Box  style = {{display:"flex", gap:"12px", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[5]?.attributes.records.map((item, index)=>
         <Box style={{height:"41px",padding:" 0px 5px 0px 5px", backgroundColor:`${( item === qustion6Data?.value) ? "#5E70DD" : "#F4F4F4"}`,borderRadius:"30px",
          display:"flex", justifyContent:"center", alignItems:"center", gap:"12px", minWidth:"81px",cursor:"pointer"}}
          onClick={() => this.handleAge(item,index, 6)}
          data-test-id = "jobsId"
          >
            <Typography  style={{fontWeight: 600, fontFamily: "Manrope",fontSize: "16px",lineHeight: "25.6px", padding: "9px, 32px, 9px, 32px"
              ,color:`${( item === qustion6Data?.value) ? "#FFFFFF" : "#080808"}`,
            }}
            >{item}</Typography></Box> )}
        </Box>
    </Box>
  }

  renderGenderDetails = () => {
    let { qustion7Data } = this.state
        return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px",marginBottom:"20px"}}>
        <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>What type of responsibilities are you looking to take on?</Typography>
        <Box style={{display:"flex", gap:"12px", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[6]?.attributes.records.map((item, index)=>
         <Box style={{height:"41px",padding:" 0px 5px 0px 5px",borderRadius:"30px", backgroundColor:`${(item === qustion7Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
          display:"flex", gap:"12px", justifyContent:"center", alignItems:"center", minWidth:"81px",cursor:"pointer"}}
          onClick={() => this.handleAge(item, index, 7)}
          data-test-id = "genderId"
          >
            <Typography 
            style={{fontFamily: "Manrope",fontSize: "16px",fontWeight: 600,lineHeight: "25.6px", padding: "9px, 32px, 9px, 32px"
            ,color:`${(item === qustion7Data?.value) ? "#FFFFFF" : "#080808"}`,
            }}
            >{item}</Typography></Box> 
        )}
        </Box>
    </Box>
  }

  renderGroupPair = () => {
    let { qustion9Data } = this.state
        return <Box style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center",marginTop:"20px", marginBottom:"20px"}}>
        <Typography style={{...webStyles.commonTypo, marginBottom:"15px"}}>What would you like to get paid?</Typography>
        <Box style={{display:"flex", gap:"12px", flexWrap: "wrap", width: "50%", justifyContent: "center"}}> { this.state.preferenceData[8]?.attributes.records.map((item, index)=>
         <Box style={{height:"41px",padding:" 0px 5px 0px 5px",borderRadius:"30px", backgroundColor:`${(item === qustion9Data?.value) ? "#5E70DD" : "#F4F4F4"}`,
          display:"flex", gap:"12px", justifyContent:"center", alignItems:"center", minWidth:"81px",cursor:"pointer"}}
          onClick={() => this.handleAge(item, index, 9)}
          data-test-id="groupId"
          >
            <Typography 
            style={{fontFamily: "Manrope",fontSize: "16px",fontWeight: 600,lineHeight: "25.6px", padding: "9px, 32px, 9px, 32px",
             color:`${(item === qustion9Data?.value) ? "#FFFFFF" : "#080808"}`,
            }}
            >{item}</Typography></Box> 
        )}
        </Box>
    </Box>
  }

  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
        <Box style={{ overflowX: "hidden" }}>            
        <Box style={{  maxWidth: "1290px",display:"flex", height: "86.08px", padding: "0px 124px 0px 124px",
          justifyContent: "space-between", backgroundColor: "#D2D2D2"}}>
            <Box style={{display:"flex", gap:"16px", justifyContent:"center", alignItems:"center"}}>
                <img src={headerLogo.default} />
                <Typography style={{fontFamily: "Manrope", fontSize: "16px", fontWeight: 500, lineHeight: "25.6px"
                }}>Find Jobs</Typography>
            </Box>
            <Box>
                <Select
                defaultValue={1}
                sx={webStyles.styledData}
                 style={{...webStyles.selectBox,width:"197px",height:"78px",backgroundColor:"#D2D2D2", border:"none"}}
                >
                    <MenuItem key={1} value={1}>David Jones</MenuItem>
                    <MenuItem key={2} value={2}>option2</MenuItem>
                </Select>
            </Box>
        </Box>
        <StyledMainDiv>
          <Box style={{paddingLeft:"124px",marginTop:"20px", width:"100%"}}>
          <Typography style={webStyles.mainText}>Preference Questions</Typography>
          </Box>
          <Box style={{marginBottom:"200px"}}>
           { this.renderAgeScreen()}
             {this.renderIntrestScreen()}
             <Box style={{width:"100%",display:"flex", justifyContent: "center"}}>
             <Box style={{width:"100%",backgroundColor:"#616161", maxWidth: "1173px", height: "1px", border: "0.5px 0px 0px 0px"}}></Box>
             </Box>
            { this.renderHelpScreen()}
            { this.renderWorkScreen()}
            <Box style={{width:"100%",display:"flex", justifyContent: "center"}}>
             <Box style={{width:"100%",backgroundColor:"#616161", maxWidth: "1173px", height: "1px", border: "0.5px 0px 0px 0px"}}></Box>
             </Box>
             {this.renderSocialMediaDetails()}
             {this.renderJobSiteDetails()}
             <Box style={{width:"100%",display:"flex", justifyContent: "center"}}>
             <Box style={{width:"100%",backgroundColor:"#616161", maxWidth: "1173px", height: "1px", border: "0.5px 0px 0px 0px"}}></Box>
             </Box>
             {this.renderGenderDetails()}
             <Box style={{width:"100%",display:"flex", justifyContent: "center"}}>
             <Box style={{width:"100%",backgroundColor:"#616161", maxWidth: "1173px", height: "1px", border: "0.5px 0px 0px 0px"}}></Box>
             </Box>

             {this.renderCertificationScreen()}
             {this.renderGroupPair()}
          </Box>
          <Box style={{...webStyles.footer, backgroundImage:`url(${Footer.default})`,backgroundSize: "cover"}}>
              <Button style={{...webStyles.alignBtn,color:"#5E70DD", border: "1px solid #5E70DD",}} >Cancel</Button>
              <Button  style={{...webStyles.alignBtn, backgroundColor: "#5E70DD"}} onClick={()=> this.prefenceApi()}>Save</Button>
            </Box>
        </StyledMainDiv>
        </Box>
    )
  }
}

export const webStyles = {
    commonTypo: {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
    },
    text:{
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24.59px",
    },
    forgotTypo: {
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "26px",
            textAlign: "left" as "left",
            marginBottom:"10px",
            cursor:"pointer"
    },
    spanText: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24.59px",
        color:"#8FA7B8",
        cursor:"pointer" 
    },
    selectBox: {
        width: "366px",
        height: "46px",
        borderRadius: "19px",
        backgroundColor: "#F4F4F4",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px"
    },
    footer: {
        width:"100%",
        height: "171px",
        border: "1px solid #747474",
        backgroundColor:"#E9E9E9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap:"10px",
        position:"fixed" as "fixed",
        bottom:"0px"
    },
    alignBtn: {
        width: "205px",
        height: "43px",
        color:"#FFFFFF",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "initial" as "initial",
        lineHeight: "25.6px",
        borderRadius: "10px",
        border: "1px solid #747474",
        cursor:"pointer"
    },
    paper: {
        borderRadius:"10px",
        border: '1px solid #F4F4F4',
    },
    mainText: {
      fontFamily: "Manrope",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "28.8px"

    },
    styledData: {
      '& .MuiSelect-select': {
        border: 'none', // Remove border from select
      },
      '& fieldset': {
        border: 'none', // Remove border from the fieldset
      },
     '& .MuiMenuItem-root': {
       backgroundColor:"white"
     },
     '& .MuiMenuItem-root.Mui-selected': {
       backgroundColor: 'white', // Change background color when selected
     },
     '& .MuiMenuItem-root:hover': {
       backgroundColor: '#b0bec5', // Change background color on hover
     },
    },
  }
  // Customizable Area End
  
