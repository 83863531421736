import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { bottomImage, image1, image2,gradiant, image3, image4, image5, image6, image7, Logo, landingImg, userIcon } from "./assets"


const StyledMainDiv = styled(Box)({
    overflowX:"hidden",
    justifyContent: "space-around",
    "@media (max-width: 457px)": {
        backgroundColor: "#F1F5F9",
        justifyContent: "center",
    },
})
const StyledBox = styled(Box)({
    "@media (max-width: 678px)": {
        display:"none"
    },
})

const StyledWrapper = styled(Box)({
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    "@media (max-width: 678px)": {
        width:"100%",
        height:"500px",
        justifyContent:"center"
    },
})
const StyledContent = styled(Box)({
    "@media (max-width: 678px)": {
        flexDirection: "column"
    },
})
const StyledmiddleWrap = styled(Box)({
     width: "100%",
    "@media (max-width: 678px)": {
    },
})
const StyledDiv = styled(Box)({
    "@media (max-width: 678px)": {
        justifyContent:"center"
    },
})

const StyledImg = styled(Box)({
    display:"none",
    "@media (max-width: 678px)": {
        display:"block",
    },
})

const StyledFooter = styled(Box)({
        width: "89.6%",
         backgroundColor: "#5E70DD",
        height: "264px",
        display: "flex",
        alignItems: "center",
        paddingRight: "80px",
        paddingLeft: "80px",
        justifyContent: "space-between",
      "@media (max-width: 678px)": {
        width: "61.6% !important",
        height: "500px !important",
        justifyContent:"center !important",
        flexDirection:"column"
    },
})
const ContactWrapper = styled(Box)({
  
  "@media (max-width: 678px)": {
    alignItems:"center",
    marginTop: "20px",
    flexDirection:"column"
},
})

const BoxWrapper = styled(Box)({
     width: "74%",
     height: "60%",
    "@media (max-width: 678px)": {
        width: "90%",
        height: "90%",
        display: "flex",
       alignItems:"center",
      flexDirection:"column"
  },
  })

  const TypoWrapper = styled("span")({
    fontFamily: "Caudex",
    fontSize: "70px",
    fontWeight: 700,
    lineHeight: "77px",
   "@media (max-width: 678px)": {
    fontSize: "24px",
    lineHeight: "26.4px"
 }
 })

 const ContentWrapper = styled("span")({
    fontFamily:"Manrope",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "25.2px",
   "@media (max-width: 678px)": {
    fontSize: "12px",
    lineHeight: "16.8px"
 }
 })

 const ImageWrapper = styled("img")({
    display:"none",
   "@media (max-width: 678px)": {
    display:"block"
 }
 })

 const Container = styled(Box)({
    display: "flex",
    height: "74px",
    alignItems: "center",
    padding: "0px 124px 0px 84px",
    justifyContent: "space-between",
   "@media (max-width: 678px)": {
    padding:"0px",
    justifyContent: "space-around",
 }
 })

 const ContainerBox = styled(Box)({
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     maxWidth: "237px",
     width: "100%",
     height: "78px",
     gap: "10px",
   "@media (max-width: 678px)": {
    display:"none"
 }
 })

 const StyledWrap = styled(Box)({
     width: "100%",
     display: "flex",
     gap: "80px",
     justifyContent: "center",
     padding: "80px",
    "@media (max-width: 678px)": {
         gap: "0px",
         padding:"0px",
        justifyContent: "center",
        flexDirection: "column-reverse",
        alignItems: "center"
    },
})

const TermsWrap = styled(Box)({
    display: "flex",
    marginTop: "113px",
    gap: "10px",
    justifyContent: "flex-end",
    paddingRight: "8.2%", 
    "@media (max-width: 678px)": {
        marginTop: "20px",
        justifyContent: "center"
    }

})


  



// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End 
    // Customizable Area Start
    render() {
        let content = [
            {image: image1, text: "Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse."},
            {image: image2, text: "Id eros pellentesque facilisi id mollis faucibus commodo enim."},
            {image: image3, text: "Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper."},
            {image: image4, text: "Curabitur magna cras euismod pharetra, mauris malesuada sit enim, elementum."},
        ]
        return (
            <StyledMainDiv>
                <Container>
                    <Box style={{display:"flex", gap:"80px"}}>
                    <img src={Logo.default} style={{ width: "195px", height: "21px" }} />
                    <ImageWrapper src={userIcon.default} />
                    </Box>
                    <ContainerBox>
                        <Typography style={{...webStyles.typo, paddingRight:"40px"}} onClick={this.handleNavigation.bind(this,"LoginPage")} >Login</Typography>
                        <Box style={{ color: "#D6DDEB", height: "48px", border: "1px solid #D6DDEB" }}></Box>
                        <Button style={webStyles.btn}  onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                    </ContainerBox>


                </Container>
                <StyledDiv style={{ height: "676px", width: "100%", gap: "40px", display: "flex",backgroundImage: `url(${gradiant.default})`, backgroundSize:"cover" }}>
                    <StyledWrapper >
                        <BoxWrapper>
                            <TypoWrapper>Find your job!</TypoWrapper>
                            <StyledImg>
                            <img src={landingImg.default}  style={{ width: "336.87px",height: "348.63px" }}/>
                            </StyledImg>
                            <Box style={{marginTop:"10px", marginBottom:"15px"}}>
                            <ContentWrapper>When you hire a teen through Senior Year, you provide an essential and motivational opportunity for a young person to connect with their broader community and find value in growing their job skills.</ContentWrapper>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <Button style={webStyles.boxBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Hire a Teen</Button>
                                <Button style={{ ...webStyles.boxBtn, border: "1px solid #5E70DD", color: "#5E70DD", backgroundColor: "transparent" }}
                                onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}
                                >Get a Job</Button>
                            </Box>
                        </BoxWrapper>
                    </StyledWrapper>
                    <StyledBox style={{ width: "45%", height: "473px" }}>
                        <img src={landingImg.default}/>
                    </StyledBox>
                </StyledDiv>
                <StyledmiddleWrap >
                    <StyledWrap>
                            <img src={bottomImage.default} style={{ maxWidth: "416px", width:"100%", height: "439px" }} />
                        <Box style={{ width: "64%", padding: "32px 0px 32px 0px", }}>
                            <StyledContent style={{ display: "flex", gap: "45px", marginBottom: "64px", flexWrap:"wrap" }}>
                                
                                {
                                    content.map((item) => 
                                <Box style={webStyles.boxAlign}>
                                    <img src={item?.image.default} style={webStyles.iconWidth} />
                                    <Typography style={webStyles.imgText}>{item.text}</Typography>
                                </Box>
                                    )}
                            </StyledContent>


                        </Box>
                    </StyledWrap>
                <StyledFooter 
                style={{
                    width: "89.6%", backgroundColor: "#5E70DD", height: "264px", display: "flex", alignItems: "center",
                    paddingRight: "80px", paddingLeft: "80px", justifyContent: "space-between"
                }}
                >
                    <Box style={{ width:"100%", maxWidth: "457px", height: "203px" }}>
                        <img src={image5.default} style={{ width: "54px", height: "43px", marginBottom: "10px" }} />
                        <Typography style={webStyles.footerText}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                        <Typography style={webStyles.emailText}>CompanyName @ 202X. All rights reserved.</Typography>
                    </Box>

                    <Box style={{height:"203px",width:"600px", marginTop:"60px" }}>
                        <ContactWrapper style={{ display: "flex", gap: "10px" }}>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image6.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Contact us at</Typography>
                                    <Typography style={webStyles.emailText}>senioryear@outlook.com</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image7.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Have a question?</Typography>
                                    <Typography style={webStyles.emailText}>310-437-2766</Typography>
                                </Box>
                            </Box>
                            <Button style={webStyles.signBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                        </ContactWrapper>
                        <Box>
                            <TermsWrap>
                                <Typography style={webStyles.emailText} onClick={this.goTermsConditions} >Terms & Conditions</Typography>
                            </TermsWrap>
                        </Box>

                    </Box>



                </StyledFooter>
                </StyledmiddleWrap>


            </StyledMainDiv>
        )
    }
}

export const webStyles = {
    typo: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#5E70DD"

    },
    iconWidth: {
        width: "40px",
        height: "36px",
        marginBottom: "16px"
    },
    imgText: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",

    },
    boxAlign: {
        maxWidth: "380px",
        width: "100%",
        height: "114px",
    },
    btn: {
        textTransform: "initial" as "initial",
        height: "50px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#5E70DD",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "25.6px",
        color: "#FFFFFF"
    },
    boxBtn: {
        width: "173px",
        textTransform: "initial" as "initial",
        backgroundColor: "#5E70DD" ,
        height: "56px",
        borderRadius: "12px",
        border: "2px 0px 0px 0px",
        color: "#FFFFFF"
    },
    footerText: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",
        color: "#FFFFFF",
        marginBottom: "80px"
    },
    emailText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "21.86px",
        color: "#FFFFFF"

    },
    smallTypo: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#ECECEC"

    },
    signBtn: {
        width: "125px",
        height: "34px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#000000",
        fontFamily: "Epilogue",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "initial" as "initial"
    }
}
// Customizable Area End
