import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errorStatus: boolean;
  error: boolean;
  showPassword: boolean;
  loginValue: string;
  passwordValue: string;
  responseMessage: string;
  rememberMe: boolean;
  loginValidationError: string;
  passwordValidationError: string;
  mailSend: boolean;
  renderTabs: string;
  passwordError: string;
  showPasswordCriteria: boolean;
  showConfirmPassword: boolean;
  validation: boolean;
  success: boolean;
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  loginApiCallId: string = "";
  labelTitle: string = "";
  forgotApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
    ];

    this.state = {
      errorStatus: false,
      error: false,
      showConfirmPassword: false,
      showPassword: false,
      loginValue: "",
      passwordValue: "",
      responseMessage: "",
      rememberMe: false,
      loginValidationError: "",
      passwordValidationError: "",
      mailSend: false,
      renderTabs:"LOGIN",
      passwordError: "",
      showPasswordCriteria: false,
      validation: false,
      success: false,
      errorMsg: "",
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ); 
      this.apiEmailLoginCallId =apiRequestCallId

    }
    
    
    // Customizable Area End
  }



   // Customizable Area Start
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } =  event.target
    if( name == "email") {
      this.validateEmail(value)
      this.setState({loginValue: value})
    }else{
      this.setState({passwordValue: value})
    }
  }

   validateEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
     if(emailRegex.test(email)){
      this.setState({loginValue: email, validation: false})
     }
     else {
      this.setState({loginValue: email, validation: true})
     }
  }

  handleNavigation = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

  forgotApi = () => {
    let tokenData = JSON.parse(String(localStorage.getItem("login")))
     this.setState({mailSend: true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenData?.token
    };
    let body = {
      "email": this.state.loginValue,
      }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

    // Customizable Area End
}
