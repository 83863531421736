export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const GoogleIcon = require("../assets/GoogleIcon.svg");
export const LoginImage = require("../assets/LoginImage.svg");
export const Gradient = require("../assets/Gradient.png");
export const Footer = require("../assets/footer.png");
export const headerLogo = require("../assets/headerLogo.svg");
export const imageLogo = require("../assets/imageLogo.svg");
export const bacckground = require("../assets/bacckground.svg");
